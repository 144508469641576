import { call, put, takeEvery } from "redux-saga/effects";
import { GET_VISA_STATUS, UPDATE_VISA_STATUS } from "./actionTypes";
import {
  getVisaStatusSuccess,
  updateVisaStatusSuccess,
  apiFailed,
} from "./actions";
import {
  getVisaStatus as getVisaStatusMethod,
  updateVisaStatus as updateVisaStatusMethod,
} from "helpers/fakebackend_helper";
import { mapVisaStatus, mapUpdatedVisaStatus } from "./context";

function* getVisaStatus({ payload: id }) {
  try {
    const response = yield call(getVisaStatusMethod, id);
    yield put(getVisaStatusSuccess(mapVisaStatus(response.data)));
  } catch (error) {
    yield put(apiFailed(error));
  }
}

function* updateVisaStatus({ payload: data }) {
  try {
    const response = yield call(updateVisaStatusMethod, data);
    yield put(updateVisaStatusSuccess(mapUpdatedVisaStatus(response.data)));
  } catch (error) {
    yield put(apiFailed(error));
  }
}

function* visaStatusSaga() {
  yield takeEvery(GET_VISA_STATUS, getVisaStatus);
  yield takeEvery(UPDATE_VISA_STATUS, updateVisaStatus);
}

export default visaStatusSaga;
