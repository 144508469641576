import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "components/Common/TableContainer";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
} from "reactstrap";
import Switch from "react-switch";
import * as Yup from "yup";
import { useFormik } from "formik";

import { String, Status, formateDate } from "./ClientsTableCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import {
  getClients as onGetClients,
  updateClient as onUpdateClient,
  deleteClientInfo as onDeleteClientInfo,
  addNewClient as onAddNewClient,
  getUnknownClients as onGetUnknownClients,
} from "store/clients/actions";
import { isEmpty } from "lodash";
//redux
import { useSelector, useDispatch } from "react-redux";

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
};

const ClientsTable = props => {
  //meta title
  document.title = "Client List | Yoga Immigration - Dashboard";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [client, setClient] = useState();
  const [switch3, setswitch3] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { clients, error } = useSelector(state => ({
    clients: state.clients.clients,
    error: state.clients.error,
  }));

  // edit client validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: (client && client.firstName) || "",
      lastName: (client && client.lastName) || "",
      email: (client && client.email) || "",
      dateOfBirth: (client && client.dateOfBirth) || "",
      gender: (client && client.gender) || "",
      passport: (client && client.passport) || "",
      phone: (client && client.phone) || "",
      emergencyContact: (client && client.emergencyContact) || "",
      address: (client && client.address) || "",
      visaCategory: (client && client.visaCategory) || "",
      status: (client && client.status) || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please Enter Valid Email")
        .required("Please Enter Your Email"),
      dateOfBirth: Yup.string().required("Please Enter Date of Birth"),
      gender: Yup.string().required("Please Select Gender"),
      passport: Yup.string().required("Please Enter Passport Number"),
      phone: Yup.string().required("Please Enter Phone Number"),
      emergencyContact: Yup.string().required(
        "Please Enter Emergency Contact Number"
      ),
      address: Yup.string().required("Please Enter Address"),
      visaCategory: Yup.string().required("Please Select Visa Category"),
      status: Yup.string().required("Please set the status"),
    }),
    onSubmit: values => {
      const updateUser = {
        id: client.id,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        date_of_birth: values.dateOfBirth,
        gender: values.gender,
        passport_no: values.passport,
        phone_no: values.phone,
        eme_contact: values.emergencyContact,
        address: values.address,
        visa_category: values.visaCategory,
        is_activated: switch3 ? "active" : "deactive",
      };
      // update user
      dispatch(onUpdateClient(updateUser));
      setIsEdit(false);
      validation.resetForm();
      toggle();
    },
  });

  const addClientValidation = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please Enter Valid Email")
        .required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Valid Password"),
      passwordConfirmation: Yup.string().required(
        "Please re-enter your password"
      ),
    }),
    onSubmit: async values => {
      const newClient = {
        first_name: values["firstName"],
        last_name: values["lastName"],
        email: values["email"],
        password: values["password"],
        password_confirmation: values["passwordConfirmation"],
      };
      dispatch(onAddNewClient(newClient));
    },
  });

  useEffect(() => {
    if (!error) {
      setModal(false);
      addClientValidation.resetForm();
    }
  }, [error]);

  const columns = useMemo(
    () => [
      // { @TODO: image
      //   Header: "Img",
      //   // accessor: "name",
      //   disableFilters: true,
      //   filterable: true,
      //   accessor: cellProps => (
      //     <>
      //       {!cellProps.img ? (
      //         <div className="avatar-xs">
      //           <span className="avatar-title rounded-circle">
      //             {cellProps.name.charAt(0)}
      //           </span>
      //         </div>
      //       ) : (
      //         <div>
      //           <img
      //             className="rounded-circle avatar-xs"
      //             src={cellProps.img}
      //             alt=""
      //           />
      //         </div>
      //       )}
      //     </>
      //   ),
      // },
      {
        Header: "Full Name",
        accessor: "fullName",
        filterable: true,
        Cell: cellProps => {
          return <String {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <String {...cellProps} />;
        },
      },
      {
        Header: "Passport",
        accessor: "passport",
        filterable: true,
        Cell: cellProps => {
          return <String {...cellProps} />;
        },
      },
      {
        Header: "Visa Category",
        accessor: "visaCategory",
        filterable: true,
        Cell: cellProps => {
          return <String {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "View Details",
        accessor: "view",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                const clientData = cellProps.row.original;
                handleViewProfile(clientData);
              }}
            >
              View Profile
            </Button>
          );
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (clients && !clients.length) {
      dispatch(onGetClients());
      setIsEdit(false);
    }
  }, [dispatch, clients]);

  useEffect(() => {
    setClient(clients);
    setIsEdit(false);
  }, [clients]);

  useEffect(() => {
    if (!isEmpty(clients) && !!isEdit) {
      setClient(clients);
      setIsEdit(false);
    }
  }, [clients]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    const userStatus = user.status === "active";
    setswitch3(userStatus);
    setClient({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      gender: user.gender,
      email: user.email,
      passport: user.passport,
      dateOfBirth: user.dateOfBirth,
      phone: user.phone,
      emergencyContact: user.emergencyContact,
      address: user.address,
      visaCategory: user.visaCategory,
      status: user.status,
    });
    setIsEdit(true);

    toggle();
  };

  const handleViewProfile = data => {
    return navigate(`/client-profile/${data.id}`);
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = clients => {
    setClient(clients);
    setDeleteModal(true);
  };

  const handleDeleteUser = async () => {
    if (client && client.id) {
      await dispatch(onDeleteClientInfo(client.id));
      dispatch(onGetUnknownClients());
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Contacts" breadcrumbItem="User List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={clients}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    isDesc
                  />

                  {!!isEdit && (
                    <Modal isOpen={modal} toggle={toggle}>
                      <ModalHeader toggle={toggle} tag="h4">
                        Edit Client
                      </ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <Row>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">First Name</Label>
                                <Input
                                  name="firstName"
                                  type="text"
                                  placeholder="Insert First Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.firstName || ""}
                                  invalid={
                                    validation.touched.firstName &&
                                    validation.errors.firstName
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.firstName &&
                                validation.errors.firstName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.firstName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Last Name</Label>
                                <Input
                                  name="lastName"
                                  placeholder="Insert Last Name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.lastName || ""}
                                  invalid={
                                    validation.touched.lastName &&
                                    validation.errors.lastName
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.lastName &&
                                validation.errors.lastName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.lastName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Passport</Label>
                                <Input
                                  name="passport"
                                  type="text"
                                  placeholder="Insert Passport Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.passport || ""}
                                  invalid={
                                    validation.touched.passport &&
                                    validation.errors.passport
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.passport &&
                                validation.errors.passport ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.passport}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  disabled
                                  name="email"
                                  type="email"
                                  placeholder="Insert Email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Date of Birth
                                </Label>
                                <Input
                                  name="dateOfBirth"
                                  type="date"
                                  placeholder="Insert Date of Birth"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.dateOfBirth || ""}
                                  invalid={
                                    validation.touched.dateOfBirth &&
                                    validation.errors.dateOfBirth
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.dateOfBirth &&
                                validation.errors.dateOfBirth ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.dateOfBirth}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Gender</Label>
                                <Input
                                  type="select"
                                  name="gender"
                                  className="form-select"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.gender || ""}
                                  invalid={
                                    validation.touched.gender &&
                                    validation.errors.gender
                                      ? true
                                      : false
                                  }
                                >
                                  <option>Female</option>
                                  <option>Male</option>
                                </Input>
                                {validation.touched.gender &&
                                validation.errors.gender ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.gender}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Phone no</Label>
                                <Input
                                  name="phone"
                                  type="text"
                                  placeholder="Insert Phone Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.phone || ""}
                                  invalid={
                                    validation.touched.phone &&
                                    validation.errors.phone
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.phone &&
                                validation.errors.phone ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.phone}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Emergency Contact
                                </Label>
                                <Input
                                  name="emergencyContact"
                                  type="text"
                                  placeholder="Insert Emergency Contact Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.emergencyContact || ""
                                  }
                                  invalid={
                                    validation.touched.emergencyContact &&
                                    validation.errors.emergencyContact
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.emergencyContact &&
                                validation.errors.emergencyContact ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.emergencyContact}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Address</Label>
                                <Input
                                  name="address"
                                  type="text"
                                  placeholder="Insert Address"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.address || ""}
                                  invalid={
                                    validation.touched.address &&
                                    validation.errors.address
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.address &&
                                validation.errors.address ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.address}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Visa Category
                                </Label>
                                <Input
                                  type="select"
                                  name="visaCategory"
                                  className="form-select"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.visaCategory || ""}
                                  invalid={
                                    validation.touched.visaCategory &&
                                    validation.errors.visaCategory
                                      ? true
                                      : false
                                  }
                                >
                                  <option>Visiting visa</option>
                                  <option>Student visa</option>
                                </Input>
                                {validation.touched.visaCategory &&
                                validation.errors.visaCategory ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.visaCategory}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label me-2">
                                  Status
                                </Label>
                                <Switch
                                  name="status"
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mb-2"
                                  onColor="#02a499"
                                  onChange={() => {
                                    setswitch3(!switch3);
                                  }}
                                  checked={switch3}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Save
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  )}
                  {!isEdit && (
                    <Modal isOpen={modal} toggle={toggle}>
                      <ModalHeader toggle={toggle} tag="h4">
                        Add New Client
                      </ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            addClientValidation.handleSubmit();
                            return false;
                          }}
                        >
                          {error ? <Alert color="danger">{error}</Alert> : null}
                          <Row>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">First Name</Label>
                                <Input
                                  name="firstName"
                                  type="text"
                                  placeholder="Insert First Name"
                                  onChange={addClientValidation.handleChange}
                                  onBlur={addClientValidation.handleBlur}
                                  value={
                                    addClientValidation.values.firstName || ""
                                  }
                                  invalid={
                                    addClientValidation.touched.firstName &&
                                    addClientValidation.errors.firstName
                                      ? true
                                      : false
                                  }
                                />
                                {addClientValidation.touched.firstName &&
                                addClientValidation.errors.firstName ? (
                                  <FormFeedback type="invalid">
                                    {addClientValidation.errors.firstName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Last Name</Label>
                                <Input
                                  name="lastName"
                                  placeholder="Insert Last Name"
                                  type="text"
                                  onChange={addClientValidation.handleChange}
                                  onBlur={addClientValidation.handleBlur}
                                  value={
                                    addClientValidation.values.lastName || ""
                                  }
                                  invalid={
                                    addClientValidation.touched.lastName &&
                                    addClientValidation.errors.lastName
                                      ? true
                                      : false
                                  }
                                />
                                {addClientValidation.touched.lastName &&
                                addClientValidation.errors.lastName ? (
                                  <FormFeedback type="invalid">
                                    {addClientValidation.errors.lastName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  type="email"
                                  placeholder="Insert Email"
                                  onChange={addClientValidation.handleChange}
                                  onBlur={addClientValidation.handleBlur}
                                  value={addClientValidation.values.email || ""}
                                  invalid={
                                    addClientValidation.touched.email &&
                                    addClientValidation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {addClientValidation.touched.email &&
                                addClientValidation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {addClientValidation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Password</Label>
                                <Input
                                  name="password"
                                  type="password"
                                  placeholder="Insert Password"
                                  onChange={addClientValidation.handleChange}
                                  onBlur={addClientValidation.handleBlur}
                                  value={
                                    addClientValidation.values.password || ""
                                  }
                                  invalid={
                                    addClientValidation.touched.password &&
                                    addClientValidation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                {addClientValidation.touched.password &&
                                addClientValidation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {addClientValidation.errors.password}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Confirm Password
                                </Label>
                                <Input
                                  name="passwordConfirmation"
                                  type="password"
                                  placeholder="Insert Password"
                                  onChange={addClientValidation.handleChange}
                                  onBlur={addClientValidation.handleBlur}
                                  value={
                                    addClientValidation.values
                                      .passwordConfirmation || ""
                                  }
                                  invalid={
                                    addClientValidation.touched
                                      .passwordConfirmation &&
                                    addClientValidation.errors
                                      .passwordConfirmation
                                      ? true
                                      : false
                                  }
                                />
                                {addClientValidation.touched
                                  .passwordConfirmation &&
                                addClientValidation.errors
                                  .passwordConfirmation ? (
                                  <FormFeedback type="invalid">
                                    {
                                      addClientValidation.errors
                                        .passwordConfirmation
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Save
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ClientsTable);
