import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const login = data =>
  post(
    url.POST_LOGIN,
    data,
    "Username and password are invalid. Please enter correct username and password"
  );

// Clients List Table
const getClientsList = () => get(url.GET_CLIENTS_LIST);

// update client
const updateClient = client =>
  post(url.UPDATE_CLIENT, client, { params: { id: client.id } });

// delete client information
const deleteClientInfo = id =>
  del(url.DELETE_CLIENT_INFO, { params: { id: id } });

// Unknown clients list
const getUnknownClientsList = () => get(url.GET_UNKNOWN_CLIENTS_LIST);

// delete unknown client
const deleteUnknownClient = id =>
  del(url.DELETE_UNKNOWN_CLIENT, { params: { id: id } });

// add client information to unknown client
const addClientInformation = client =>
  post(url.ADD_CLIENT_INFO, client, { params: { id: client.id } });

// add new client
const addNewClient = client => post(url.ADD_NEW_CLIENT, client);

/* Client Profile */
// get client profile
const getClientProfile = id => get(url.GET_CLIENT_PROFILE, { params: { id } });

// update current message
const postCurrentMessage = data =>
  post(url.POST_CURRENT_MESSAGE, data, { params: { id: data.id } });

// update visa submission status
const postUpdateVisaSubmissionStatus = data =>
  post(url.POST_UPDATE_VISA_SUBMISSION_STATUS, data, {
    params: { id: data.id },
  });

// update status percentage
const postStatusPercentage = data =>
  post(url.POST_STATUS_PERCENTAGE, data, { params: { id: data.id } });

// edit particular document
const updateClientDocument = data =>
  post(url.POST_EDIT_PARTICULAR_DOCUMENT, data, { params: { id: data.id } });

// delete particular document
const deleteParticularDocument = data =>
  del(url.DELETE_PARTICULAR_DOCUMENT, { params: { id: data.id } });

// add new document
const postAddNewDocument = data =>
  post(url.ADD_NEW_DOCUMENT, data, { params: { userId: data.userId } });

// edit particular payment
const updateClientPayment = data =>
  post(url.POST_EDIT_PARTICULAR_PAYMENT, data, { params: { id: data.id } });

// delete particular payment
const deleteParticularPayment = data =>
  del(url.DELETE_PARTICULAR_PAYMENT, { params: { id: data.id } });

// add new payment
const postAddNewPayment = data =>
  post(url.ADD_NEW_PAYMENT, data, { params: { userId: data.userId } });

// Get visa status
const getVisaStatus = id => get(url.GET_VISA_STATUS, { params: { id } });

// Update Visa Status
const updateVisaStatus = data =>
  post(url.UPDATE_VISA_STATUS, data, { params: { id: data.id } });

///////////////////////////////////////////////////////////////////////////
////////////////////// TODO: DELETES //////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  login,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtForgetPwd,
  postJwtProfile,

  //
  getClientsList,
  updateClient,
  deleteClientInfo,
  getUnknownClientsList,
  deleteUnknownClient,
  addClientInformation,
  addNewClient,
  getClientProfile,
  postCurrentMessage,
  postUpdateVisaSubmissionStatus,
  postStatusPercentage,
  updateClientDocument,
  deleteParticularDocument,
  postAddNewDocument,
  updateClientPayment,
  deleteParticularPayment,
  postAddNewPayment,
  getVisaStatus,
  updateVisaStatus,
};
