export const clientProfileMap = data => ({
  clientInfo: {
    id: data.client_info.id,
    firstName: data.client_info.first_name,
    lastName: data.client_info.last_name,
    fullName: `${data.client_info.first_name} ${data.client_info.last_name}`,
    dateOfBirth: data.client_info.date_of_birth,
    gender: data.client_info.gender,
    email: data.client_info.email,
    passport: data.client_info.passport_no,
    phone: data.client_info.phone_no,
    emergencyContact: data.client_info.eme_contact,
    address: data.client_info.address,
    visaCategory: data.client_info.visa_category,
    status: data.client_info.is_activated,
    registeredAt: data.client_info.created_at,
  },
  documentsList: data.client_documents.map(document => ({
    id: document.id,
    document: document.document,
    status: document.status,
  })),
  payments: data.client_payments.map(payment => ({
    id: payment.id,
    payment: payment.payment,
    amount: payment.amount,
    status: payment.status,
  })),
  clientProfile: {
    id: data.client_profile.id,
    updateMessage: data.client_profile.update_message,
    applicationStatus: data.client_profile.application_status,
    visaSubmitted: data.client_profile.visa_submitted === "true",
    createdAt: data.client_profile.created_at,
    updatedAt: data.client_profile.updated_at,
  },
});

export const clientMessageProfileMap = data => ({
  id: data.id,
  updateMessage: data.update_message,
  applicationStatus: data.application_status,
  visaSubmitted: data.visa_submitted === "true",
  createdAt: data.created_at,
  updatedAt: data.updated_at,
});

export const clientDocumentMap = data => ({
  id: data.id,
  document: data.document,
  status: data.status,
});

export const clientPaymentMap = data => ({
  id: data.id,
  payment: data.payment,
  amount: data.amount,
  status: data.status,
});
