import React from "react";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { formateDate } from "utils/date";
import StatusBadge from "components/Common/statusBadge";

const PersonalInformation = ({ clientInfo }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-2">Personal Information</CardTitle>
        <div className="table-responsive">
          <Table className="table-nowrap mb-0">
            <tbody>
              <tr>
                <th scope="row">First Name :</th>
                <td>{clientInfo?.firstName}</td>
              </tr>
              <tr>
                <th scope="row">Last Name :</th>
                <td>{clientInfo?.lastName}</td>
              </tr>
              <tr>
                <th scope="row">E-mail :</th>
                <td>{clientInfo?.email}</td>
              </tr>
              <tr>
                <th scope="row">Data of Birth :</th>
                <td>{clientInfo?.dateOfBirth}</td>
              </tr>
              <tr>
                <th scope="row">Gender :</th>
                <td>{clientInfo?.gender}</td>
              </tr>
              <tr>
                <th scope="row">Passport No :</th>
                <td>{clientInfo?.passport}</td>
              </tr>
              <tr>
                <th scope="row">Address :</th>
                <td>{clientInfo?.address}</td>
              </tr>
              <tr>
                <th scope="row">Phone No :</th>
                <td>{clientInfo?.phone}</td>
              </tr>
              <tr>
                <th scope="row">Emergency Contact No :</th>
                <td>{clientInfo?.emergencyContact}</td>
              </tr>
              <tr>
                <th scope="row">Profile Status :</th>
                <td>
                  <StatusBadge value={clientInfo?.status} />
                </td>
              </tr>
              <tr>
                <th scope="row">Profile Created :</th>
                <td>{formateDate(clientInfo?.registeredAt)}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default PersonalInformation;
