import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getClients as onGetClients } from "store/clients/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Dashboard = props => {
  //meta title
  document.title = "Dashboard | Yoga Immigration";

  const dispatch = useDispatch();
  const { clients } = useSelector(state => ({
    clients: state.clients.clients,
  }));

  useEffect(() => {
    if (clients && !clients.length) {
      dispatch(onGetClients());
    }
  }, [dispatch]);

  const reports = [
    {
      title: "Total Clients",
      iconClass: "bx-copy-alt",
      description: clients?.length,
    },
    // { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    // {
    //   title: "Average Price",
    //   iconClass: "bx-purchase-tag-alt",
    //   description: "$16.2",
    // },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
