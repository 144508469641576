import React from "react";
import { Badge } from "reactstrap";

const StatusBadge = ({ value }) => (
  <Badge
    className={
      "font-size-12 badge-soft-" + (value === "active" ? "success" : "danger")
    }
  >
    {value === "active" ? "Active" : "Deactive"}
  </Badge>
);

export const ReceivedStatusBadge = ({ value }) => (
  <Badge
    className={
      "font-size-12 badge-soft-" + (value === "on" ? "success" : "danger")
    }
  >
    {value === "on" ? "Received" : "Not Received"}
  </Badge>
);

export const VisaSubmissionStatusBadge = ({ value }) => (
  <Badge
    className={
      "font-size-12 badge-soft-" + (value === true ? "success" : "danger")
    }
  >
    {value === true ? "Submitted" : "Not Submitted"}
  </Badge>
);

export default StatusBadge;
