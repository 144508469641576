import React, { useEffect, useMemo, useRef, useState } from "react";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import {
  getUnknownClients as onGetUnknownClients,
  addClientInfo as onAddClientInfo,
  deleteUnknownClient as onDeleteUnknownClient,
} from "store/clients/actions";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import TableContainer from "components/Common/TableContainer";
import { String, formateDate } from "pages/ClientsTable/ClientsTableCol";

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
};

const UnknownClientsTable = () => {
  document.title = "Unknown Clients | Yoga Immigration";
  const dispatch = useDispatch();
  const [client, setClient] = useState();
  const [switch3, setswitch3] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [modal, setModal] = useState(false);

  const { clients } = useSelector(state => ({
    clients: state.clients.unknownClients,
  }));
  // const status = switch3 ? "active" : "deactive";
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: (client && client.firstName) || "",
      lastName: (client && client.lastName) || "",
      email: (client && client.email) || "",
      status: "deactive",
      gender: "Male",
      visaCategory: "Visiting visa",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please Enter Valid Email")
        .required("Please Enter Your Email"),
      dateOfBirth: Yup.string().required("Please Enter Date of Birth"),
      gender: Yup.string().required("Please Select Gender"),
      visaCategory: Yup.string().required("Please Select Visa Category"),
      passport: Yup.string().required("Please Enter Passport Number"),
      phone: Yup.string().required("Please Enter Phone Number"),
      emergencyContact: Yup.string().required(
        "Please Enter Emergency Contact Number"
      ),
      address: Yup.string().required("Please Enter Address"),
    }),
    onSubmit: values => {
      const switchValue = values.status !== "deactive" ? "active" : "deactive";
      const addUserInfo = {
        first_name: values["firstName"],
        last_name: values["lastName"],
        email: values["email"],
        date_of_birth: values["dateOfBirth"],
        gender: values["gender"],
        passport_no: values["passport"],
        phone_no: values["phone"],
        eme_contact: values["emergencyContact"],
        address: values["address"],
        visa_category: values["visaCategory"],
        is_activated: switchValue,
      };
      // add user information
      dispatch(onAddClientInfo(addUserInfo));
      validation.resetForm();
      toggle();
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "fullName",
        filterable: true,
        Cell: cellProps => {
          return <String {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <String {...cellProps} />;
        },
      },
      {
        Header: "Registered",
        accessor: "registeredAt",
        filterable: true,
        Cell: cellProps => {
          const date = cellProps.value;
          return formateDate(date);
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (clients && !clients.length) {
      dispatch(onGetUnknownClients());
    }
  }, [dispatch]);

  useEffect(() => {
    setClient(clients);
  }, [clients]);

  useEffect(() => {
    if (!isEmpty(clients)) {
      setClient(clients);
    }
  }, [clients]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setClient({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    });

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = clients => {
    setClient(clients);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (client && client.id) {
      dispatch(onDeleteUnknownClient(client.id));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setClientList("");
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Clients" breadcrumbItem="Unknown Clients List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={clients}
                    isGlobalFilter={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      Add User Information
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">First Name</Label>
                              <Input
                                name="firstName"
                                type="text"
                                placeholder="Insert First Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstName || ""}
                                invalid={
                                  validation.touched.firstName &&
                                  validation.errors.firstName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.firstName &&
                              validation.errors.firstName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.firstName}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Last Name</Label>
                              <Input
                                name="lastName"
                                placeholder="Insert Last Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastName || ""}
                                invalid={
                                  validation.touched.lastName &&
                                  validation.errors.lastName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lastName &&
                              validation.errors.lastName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lastName}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Passport</Label>
                              <Input
                                name="passport"
                                type="text"
                                placeholder="Insert Passport Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.passport || ""}
                                invalid={
                                  validation.touched.passport &&
                                  validation.errors.passport
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.passport &&
                              validation.errors.passport ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.passport}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                disabled
                                name="email"
                                type="email"
                                placeholder="Insert Email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">
                                Date of Birth
                              </Label>
                              <Input
                                name="dateOfBirth"
                                type="date"
                                placeholder="Insert Date of Birth"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.dateOfBirth || ""}
                                invalid={
                                  validation.touched.dateOfBirth &&
                                  validation.errors.dateOfBirth
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.dateOfBirth &&
                              validation.errors.dateOfBirth ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.dateOfBirth}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Gender</Label>
                              <Input
                                type="select"
                                name="gender"
                                className="form-select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gender || ""}
                                invalid={
                                  validation.touched.gender &&
                                  validation.errors.gender
                                    ? true
                                    : false
                                }
                              >
                                <option>Male</option>
                                <option>Female</option>
                              </Input>
                              {validation.touched.gender &&
                              validation.errors.gender ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.gender}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Phone no</Label>
                              <Input
                                name="phone"
                                type="text"
                                placeholder="Insert Phone Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone &&
                                  validation.errors.phone
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.phone &&
                              validation.errors.phone ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.phone}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">
                                Emergency Contact
                              </Label>
                              <Input
                                name="emergencyContact"
                                type="text"
                                placeholder="Insert Emergency Contact Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.emergencyContact || ""}
                                invalid={
                                  validation.touched.emergencyContact &&
                                  validation.errors.emergencyContact
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.emergencyContact &&
                              validation.errors.emergencyContact ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.emergencyContact}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Address</Label>
                              <Input
                                name="address"
                                type="text"
                                placeholder="Insert Address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                  validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.address &&
                              validation.errors.address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.address}
                                </FormFeedback>
                              ) : null}
                            </div>
                            {/* <div className="mb-3">
                              <Label className="form-label me-2">Status</Label>
                              <Switch
                                name="status"
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                className="me-1 mb-sm-8 mb-2"
                                onColor="#02a499"
                                value={validation.values.status || ""}
                                onChange={() => {
                                  setswitch3(!switch3)
                                }}
                                checked={switch3}
                              />
                            </div> */}
                            <div className="mb-3">
                              <Label className="form-label">
                                Visa Category
                              </Label>
                              <Input
                                type="select"
                                name="visaCategory"
                                className="form-select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.visaCategory || ""}
                                invalid={
                                  validation.touched.visaCategory &&
                                  validation.errors.visaCategory
                                    ? true
                                    : false
                                }
                              >
                                <option>Visiting visa</option>
                                <option>Student visa</option>
                              </Input>
                              {validation.touched.visaCategory &&
                              validation.errors.visaCategory ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.visaCategory}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="form-check form-switch form-switch-md ps-0 mb-3 form-check-right">
                              <Label className="form-label me-3">Status</Label>
                              <Input
                                name="status"
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.status}
                                invalid={
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.status &&
                              validation.errors.status ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.status}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UnknownClientsTable);
