import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Inner components
import Dashboard from "../pages/Dashboard/index";
import ClientsTable from "pages/ClientsTable";
import UnknownClientsTable from "pages/UnknownClientsTable";
import ClientProfile from "pages/ClientProfile";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/clients", component: <ClientsTable /> },
  { path: "/unknown-clients", component: <UnknownClientsTable /> },
  { path: "/client-profile/:id", component: <ClientProfile /> },

  // //profile
  // { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // TODO: Other routes
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
