import { call, put, takeEvery } from "redux-saga/effects";
import { setLocalStorage } from "utils/localStorage";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess } from "./actions";
import { login } from "../../../helpers/fakebackend_helper";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(login, {
      email: user.email,
      password: user.password,
    });
    localStorage.setItem("authUser", response.token);
    setLocalStorage("isAdmin", response.user.role === 1);
    setLocalStorage("authUser", response.user.first_name);
    yield put(loginSuccess(response));
    history("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    history("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
