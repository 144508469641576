import { combineReducers } from "redux";
import { GLOBAL_RESET } from "./auth/login/actionTypes";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";
// Clients
import clients from "./clients/reducer";
// Client Profile
import ClientProfile from "./client-profile/reducer";
// Visa Status
import VisaStatus from "./visa-status/reducer";

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Dashboard,
  clients,
  ClientProfile,
  VisaStatus,
});

const rootReducer = (state, action) => {
  if (action.type === GLOBAL_RESET) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
