import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PROFILE,
  UPDATE_CURRENT_MESSAGE,
  UPDATE_VISA_SUBMISSION_STATUS,
  UPDATE_STATUS_PERCENTAGE,
  EDIT_PARTICULAR_DOCUMENT,
  DELETE_PARTICULAR_DOCUMENT,
  ADD_NEW_DOCUMENT,
  EDIT_PARTICULAR_PAYMENT,
  ADD_NEW_PAYMENT,
  DELETE_PARTICULAR_PAYMENT,
} from "./actionTypes";
import {
  getProfileSuccess,
  getProfileFail,
  updateCurrentMessageSuccess,
  updateCurrentMessageFail,
  updateVisaSubmissionStatusSuccess,
  updateVisaSubmissionStatusSuccessFail,
  updateStatusPercentageSuccess,
  updateStatusPercentageFail,
  editParticularDocumentSuccess,
  editParticularDocumentFail,
  deleteParticularDocumentSuccess,
  deleteParticularDocumentFail,
  addNewDocumentSuccess,
  addNewDocumentFail,
  editParticularPaymentSuccess,
  editParticularPaymentFail,
  addNewPaymentSuccess,
  addNewPaymentFail,
  deleteParticularPaymentSuccess,
  deleteParticularPaymentFail,
} from "./actions";
import {
  getClientProfile,
  postCurrentMessage,
  postUpdateVisaSubmissionStatus,
  postStatusPercentage,
  updateClientDocument,
  deleteParticularDocument,
  postAddNewDocument,
  updateClientPayment,
  deleteParticularPayment,
  postAddNewPayment,
} from "helpers/fakebackend_helper";
import {
  clientProfileMap,
  clientMessageProfileMap,
  clientDocumentMap,
  clientPaymentMap,
} from "./context";

// get all client information for profile
function* getProfile({ payload: client }) {
  try {
    const response = yield call(getClientProfile, client);
    yield put(getProfileSuccess(clientProfileMap(response.data)));
  } catch (error) {
    yield put(getProfileFail(error));
  }
}

// update current message
function* updateCurrentMessage({ payload: data }) {
  try {
    const response = yield call(postCurrentMessage, data);
    yield put(
      updateCurrentMessageSuccess(clientMessageProfileMap(response.data))
    );
  } catch (error) {
    yield put(updateCurrentMessageFail(error));
  }
}

// update visa submission status
function* updateVisaSubmissionStatus({ payload: data }) {
  try {
    const response = yield call(postUpdateVisaSubmissionStatus, data);
    yield put(
      updateVisaSubmissionStatusSuccess(clientMessageProfileMap(response.data))
    );
  } catch (error) {
    yield put(updateVisaSubmissionStatusSuccessFail(error));
  }
}

// update status percentage
function* updateStatusPercentage({ payload: data }) {
  try {
    const response = yield call(postStatusPercentage, data);
    yield put(
      updateStatusPercentageSuccess(clientMessageProfileMap(response.data))
    );
  } catch (error) {
    yield put(updateStatusPercentageFail(error));
  }
}

// edit particular document
function* editParticularDocument({ payload: data }) {
  try {
    const response = yield call(updateClientDocument, data);
    yield put(editParticularDocumentSuccess(clientDocumentMap(response.data)));
  } catch (error) {
    yield put(editParticularDocumentFail(error));
  }
}

// delete particular document
function* onDeleteParticularDocument({ payload: data }) {
  try {
    const response = yield call(deleteParticularDocument, data);
    yield put(deleteParticularDocumentSuccess(response));
  } catch (error) {
    yield put(deleteParticularDocumentFail(error));
  }
}

// add new document
function* onAddNewDocument({ payload: data }) {
  try {
    const response = yield call(postAddNewDocument, data);
    yield put(addNewDocumentSuccess(response.data));
  } catch (error) {
    yield put(addNewDocumentFail(error));
  }
}
// here
// edit particular payment
function* editParticularPayment({ payload: data }) {
  try {
    const response = yield call(updateClientPayment, data);
    yield put(editParticularPaymentSuccess(clientPaymentMap(response.data)));
  } catch (error) {
    yield put(editParticularPaymentFail(error));
  }
}

// delete particular payment
function* onDeleteParticularPayment({ payload: data }) {
  try {
    const response = yield call(deleteParticularPayment, data);
    yield put(deleteParticularPaymentSuccess(response));
  } catch (error) {
    yield put(deleteParticularPaymentFail(error));
  }
}

// add new payment
function* onAddNewPayment({ payload: data }) {
  try {
    const response = yield call(postAddNewPayment, data);
    yield put(addNewPaymentSuccess(response.data));
  } catch (error) {
    yield put(addNewPaymentFail(error));
  }
}

function* clientsSaga() {
  yield takeEvery(GET_PROFILE, getProfile);
  yield takeEvery(UPDATE_CURRENT_MESSAGE, updateCurrentMessage);
  yield takeEvery(UPDATE_VISA_SUBMISSION_STATUS, updateVisaSubmissionStatus);
  yield takeEvery(UPDATE_STATUS_PERCENTAGE, updateStatusPercentage);
  yield takeEvery(EDIT_PARTICULAR_DOCUMENT, editParticularDocument);
  yield takeEvery(DELETE_PARTICULAR_DOCUMENT, onDeleteParticularDocument);
  yield takeEvery(ADD_NEW_DOCUMENT, onAddNewDocument);
  yield takeEvery(EDIT_PARTICULAR_PAYMENT, editParticularPayment);
  yield takeEvery(DELETE_PARTICULAR_PAYMENT, onDeleteParticularPayment);
  yield takeEvery(ADD_NEW_PAYMENT, onAddNewPayment);
}

export default clientsSaga;
