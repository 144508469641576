import React, { useMemo, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  CardText,
  CardFooter,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import {
  editParticularDocument as onEditParticularDocument,
  deleteParticularDocument as onDeleteParticularDocument,
  addNewDocument as onAddNewDocument,
} from "store/client-profile/actions";
import DeleteModal from "components/Common/DeleteModal";
import TableContainer from "components/Common/TableContainer";
import { String } from "pages/ClientsTable/ClientsTableCol";
import { ReceivedStatusBadge } from "components/Common/statusBadge/statusBadge";

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
};

const DocumentsTable = ({ documentsList, clientId }) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isNewDocumentModelOpen, setIsNewDocumentModelOpen] = useState(false);
  const [switch3, setswitch3] = useState(false);
  const [documentData, setDocumentData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      document: (documentData && documentData.document) || "",
      status: (documentData && documentData.status) || "",
    },
    validationSchema: Yup.object({
      document: Yup.string().required("Please enter the document name"),
      status: Yup.string().required("Please set the status"),
    }),
    onSubmit: values => {
      const updatedValue = {
        id: documentData.id,
        document: values.document,
        status: switch3 ? "on" : "off",
      };
      dispatch(onEditParticularDocument(updatedValue));
      validation.resetForm();
      setIsModelOpen(false);
      // toggle();
    },
  });

  const addNewDocumentValidation = useFormik({
    initialValues: {
      document: "",
      status: "off",
    },
    validationSchema: Yup.object({
      document: Yup.string().required("Please enter the document name"),
      status: Yup.string().required("Please set the status"),
    }),
    onSubmit: values => {
      const newDocument = {
        userId: clientId,
        document: values["document"],
        status: switch3 ? "on" : "off",
      };
      dispatch(onAddNewDocument(newDocument));
      addNewDocumentValidation.resetForm();
      handleAddNewInformation();
    },
  });

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
    isModelOpen && setswitch3(false);
  };

  const toggleAddNewDocument = () => {
    setIsNewDocumentModelOpen(!isNewDocumentModelOpen);
    isNewDocumentModelOpen && setswitch3(false);
  };

  const handleAddNewInformation = () => {
    setIsModelOpen(false);
    toggleAddNewDocument();
  };

  const handleEditDocumentClick = doc => {
    const documentStatus = doc.status === "on";
    setswitch3(documentStatus);
    setDocumentData({
      id: doc.id,
      document: doc.document,
      status: doc.status,
    });
    setIsModelOpen(true);
    toggle();
  };

  const onClickDelete = doc => {
    setDocumentData(doc);
    setDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Documents",
        accessor: "document",
        filterable: true,
        Cell: cellProps => {
          return <String {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <ReceivedStatusBadge value={cellProps.value} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const documentData = cellProps.row.original;
                  handleEditDocumentClick(documentData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const documentData = cellProps.row.original;
                  onClickDelete(documentData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleDeleteUser = () => {
    if (documentData && documentData.id) {
      dispatch(onDeleteParticularDocument(documentData));
    }
    setDeleteModal(false);
    onPaginationPageChange(1);
  };

  return (
    <Card>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <CardBody>
        <TableContainer
          columns={columns}
          data={documentsList}
          isGlobalFilter={true}
          customPageSize={10}
          isAddNewInformation
          addInformationBtnLabel="Add New Document"
          handleAddNewInformation={handleAddNewInformation}
        ></TableContainer>
      </CardBody>
      {!!isModelOpen && (
        <Modal isOpen={isModelOpen} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            Edit Document
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label>Document</Label>
                    <Input
                      name="document"
                      type="text"
                      value={validation.values.document || ""}
                      placeholder="Update the document name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.document &&
                        validation.errors.document
                          ? true
                          : false
                      }
                    />
                    {validation.touched.document &&
                    validation.errors.document ? (
                      <FormFeedback type="invalid">
                        {validation.errors.document}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label me-2">Status</Label>
                    <Switch
                      name="status"
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#02a499"
                      onChange={() => {
                        setswitch3(!switch3);
                      }}
                      checked={switch3}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      )}
      {!!isNewDocumentModelOpen && (
        <Modal isOpen={isNewDocumentModelOpen} toggle={toggleAddNewDocument}>
          <ModalHeader toggle={toggleAddNewDocument} tag="h4">
            Add New Document
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault();
                addNewDocumentValidation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label>Document</Label>
                    <Input
                      name="document"
                      type="text"
                      value={addNewDocumentValidation.values.document || ""}
                      placeholder="Add document name"
                      onChange={addNewDocumentValidation.handleChange}
                      onBlur={addNewDocumentValidation.handleBlur}
                      invalid={
                        addNewDocumentValidation.touched.document &&
                        addNewDocumentValidation.errors.document
                          ? true
                          : false
                      }
                    />
                    {addNewDocumentValidation.touched.document &&
                    addNewDocumentValidation.errors.document ? (
                      <FormFeedback type="invalid">
                        {addNewDocumentValidation.errors.document}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label me-2">Status</Label>
                    <Switch
                      name="status"
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#02a499"
                      onChange={() => {
                        setswitch3(!switch3);
                      }}
                      checked={switch3}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </Card>
  );
};

export default DocumentsTable;
