import React, { useMemo, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import {
  editParticularPayment as onEditParticularPayment,
  deleteParticularPayment as onDeleteParticularPayment,
  addNewPayment as onAddNewPayment,
} from "store/client-profile/actions";
import DeleteModal from "components/Common/DeleteModal";
import TableContainer from "components/Common/TableContainer";
import { String } from "pages/ClientsTable/ClientsTableCol";
import { formattedCurrency } from "utils/currency";
import { ReceivedStatusBadge } from "components/Common/statusBadge/statusBadge";

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
};

const PaymentsTable = ({ payments, clientId }) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isNewPaymentModelOpen, setIsNewPaymentModelOpen] = useState(false);
  const [switch3, setswitch3] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const amountFormat = paymentData?.amount.split(" ");

  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      payment: (paymentData && paymentData.payment) || "",
      amountFormat: "LKR",
      amount: (paymentData && paymentData.amount) || "",
      status: (paymentData && paymentData.status) || "",
    },
    validationSchema: Yup.object({
      payment: Yup.string().required("Please enter the payment name"),
      amount: Yup.string().required("Please enter the amount"),
      status: Yup.string().required("Please set the status"),
    }),
    onSubmit: values => {
      const updatedValue = {
        id: paymentData.id,
        payment: values.payment,
        amount:
          values.amountFormat +
          " " +
          (values.amount.split(" ")[1] || values.amount.split(" ")[0]),
        status: switch3 ? "on" : "off",
      };
      dispatch(onEditParticularPayment(updatedValue));
      validation.resetForm();
      setIsModelOpen(false);
      // toggle();
    },
  });

  const addNewPaymentValidation = useFormik({
    initialValues: {
      payment: "",
      amountFormat: "LKR",
      amount: "",
      status: "off",
    },
    validationSchema: Yup.object({
      payment: Yup.string().required("Please enter the payment name"),
      amount: Yup.string().required("Please enter the amount"),
      status: Yup.string().required("Please set the status"),
    }),
    onSubmit: values => {
      const newPayment = {
        userId: clientId,
        payment: values["payment"],
        amount: values["amountFormat"] + " " + values["amount"],
        status: switch3 ? "on" : "off",
      };
      dispatch(onAddNewPayment(newPayment));
      addNewPaymentValidation.resetForm();
      handleAddNewPayment();
    },
  });

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
    isModelOpen && setswitch3(false);
  };

  const toggleAddNewPayment = () => {
    setIsNewPaymentModelOpen(!isNewPaymentModelOpen);
    isNewPaymentModelOpen && setswitch3(false);
  };

  const handleAddNewPayment = () => {
    setIsModelOpen(false);
    toggleAddNewPayment();
  };

  const handleEditPaymentClick = val => {
    const paymentStatus = val.status === "on";
    setswitch3(paymentStatus);
    setPaymentData({
      id: val.id,
      payment: val.payment,
      amount: val.amount,
      status: val.status,
    });
    setIsModelOpen(true);
    toggle();
  };

  const onClickDelete = doc => {
    setPaymentData(doc);
    setDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Payment",
        accessor: "payment",
        filterable: true,
        Cell: cellProps => {
          return <String {...cellProps} />;
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: true,
        Cell: cellProps => {
          const amountVal = cellProps.value.split(" ");
          return formattedCurrency(amountVal[1], amountVal[0]);
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <ReceivedStatusBadge value={cellProps.value} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const documentData = cellProps.row.original;
                  handleEditPaymentClick(documentData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const documentData = cellProps.row.original;
                  onClickDelete(documentData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleDeleteUser = () => {
    if (paymentData && paymentData.id) {
      dispatch(onDeleteParticularPayment(paymentData));
    }
    setDeleteModal(false);
    onPaginationPageChange(1);
  };

  return (
    <Card>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <CardBody>
        <TableContainer
          columns={columns}
          data={payments}
          isGlobalFilter={true}
          customPageSize={10}
          isAddNewInformation
          addInformationBtnLabel="Add New Payment"
          handleAddNewInformation={handleAddNewPayment}
        ></TableContainer>
      </CardBody>
      {!!isModelOpen && (
        <Modal isOpen={isModelOpen} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            Edit Payment Info
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label>Payment</Label>
                    <Input
                      name="payment"
                      type="text"
                      value={validation.values.payment || ""}
                      placeholder="Update the Payment"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.payment && validation.errors.payment
                          ? true
                          : false
                      }
                    />
                    {validation.touched.payment && validation.errors.payment ? (
                      <FormFeedback type="invalid">
                        {validation.errors.payment}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label>Amount</Label>
                    <div className="input-group mb-3">
                      <div className="col-sm-auto">
                        <select
                          name="amountFormat"
                          defaultValue={amountFormat[0]}
                          className="form-select"
                          onChange={validation.handleChange}
                        >
                          <option value="LKR">LKR</option>
                          <option value="CAD">CAD</option>
                        </select>
                      </div>
                      <Input
                        name="amount"
                        type="text"
                        value={
                          validation.values.amount.split(" ")[1] ||
                          validation.values.amount.split(" ")[0] ||
                          ""
                        }
                        placeholder="Update the Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.amount && validation.errors.amount
                            ? true
                            : false
                        }
                      />
                    </div>
                    {validation.touched.amount && validation.errors.amount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.amount}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label me-2">Status</Label>
                    <Switch
                      name="status"
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#02a499"
                      onChange={() => {
                        setswitch3(!switch3);
                      }}
                      checked={switch3}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      )}
      {!!isNewPaymentModelOpen && (
        <Modal isOpen={isNewPaymentModelOpen} toggle={toggleAddNewPayment}>
          <ModalHeader toggle={toggleAddNewPayment} tag="h4">
            Add New Payment Information
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault();
                addNewPaymentValidation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label>Payment</Label>
                    <Input
                      name="payment"
                      type="text"
                      value={addNewPaymentValidation.values.payment || ""}
                      placeholder="Add payment name"
                      onChange={addNewPaymentValidation.handleChange}
                      onBlur={addNewPaymentValidation.handleBlur}
                      invalid={
                        addNewPaymentValidation.touched.payment &&
                        addNewPaymentValidation.errors.payment
                          ? true
                          : false
                      }
                    />
                    {addNewPaymentValidation.touched.payment &&
                    addNewPaymentValidation.errors.payment ? (
                      <FormFeedback type="invalid">
                        {addNewPaymentValidation.errors.payment}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label>Amount</Label>
                    <div className="input-group mb-3">
                      <div className="col-sm-auto">
                        <select
                          name="amountFormat"
                          value={addNewPaymentValidation.values.amountFormat}
                          className="form-select"
                          onChange={addNewPaymentValidation.handleChange}
                        >
                          <option value="LKR">LKR</option>
                          <option value="CAD">CAD</option>
                        </select>
                      </div>
                      <Input
                        name="amount"
                        type="text"
                        value={addNewPaymentValidation.values.amount || ""}
                        placeholder="Add amount"
                        onChange={addNewPaymentValidation.handleChange}
                        onBlur={addNewPaymentValidation.handleBlur}
                        invalid={
                          addNewPaymentValidation.touched.amount &&
                          addNewPaymentValidation.errors.amount
                            ? true
                            : false
                        }
                      />
                    </div>
                    {addNewPaymentValidation.touched.amount &&
                    addNewPaymentValidation.errors.amount ? (
                      <FormFeedback type="invalid">
                        {addNewPaymentValidation.errors.amount}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label me-2">Status</Label>
                    <Switch
                      name="status"
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#02a499"
                      onChange={() => {
                        setswitch3(!switch3);
                      }}
                      checked={switch3}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </Card>
  );
};

export default PaymentsTable;
