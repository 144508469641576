import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_CLIENTS,
  UPDATE_CLIENT,
  DELETE_CLIENT_INFO,
  GET_UNKNOWN_CLIENTS,
  DELETE_UNKNOWN_CLIENT,
  ADD_CLIENT_INFO,
  ADD_NEW_CLIENT,
} from "./actionTypes";
import {
  getClientsSuccess,
  getClientsFail,
  updateClientSuccess,
  updateClientFail,
  deleteClientInfoSuccess,
  deleteClientInfoFail,
  getUnknownClientsSuccess,
  getUnknownClientsFail,
  deleteUnknownClientSuccess,
  deleteUnknownClientFail,
  addClientInfoSuccess,
  addClientInfoFail,
  addNewClientSuccess,
  addNewClientFail,
} from "./actions";
import {
  getClientsList,
  updateClient,
  deleteClientInfo,
  getUnknownClientsList,
  deleteUnknownClient,
  addClientInformation,
  addNewClient,
} from "helpers/fakebackend_helper";
import {
  mapClients,
  mapUnknownClients,
  mapClientV2,
  mapUnknownClient,
} from "./context";

function* fetchClients() {
  try {
    const response = yield call(getClientsList);
    yield put(getClientsSuccess(mapClients(response.clients)));
  } catch (error) {
    yield put(getClientsFail(error));
  }
}

function* onUpdateClient({ payload: client }) {
  try {
    const response = yield call(updateClient, client);
    yield put(updateClientSuccess(mapClientV2(response.client)));
  } catch (error) {
    yield put(updateClientFail(error));
  }
}

// delete client information
function* onDeleteClientInfo({ payload: id }) {
  try {
    const response = yield call(deleteClientInfo, id);
    yield put(deleteClientInfoSuccess(response.id));
  } catch (error) {
    yield put(deleteClientInfoFail(error));
  }
}

// get all unknown clients
function* fetchUnknownClients() {
  try {
    const response = yield call(getUnknownClientsList);
    yield put(getUnknownClientsSuccess(mapUnknownClients(response.clients)));
  } catch (error) {
    yield put(getUnknownClientsFail(error));
  }
}

// delete unknown client
function* onDeleteUnknownClient({ payload: id }) {
  try {
    const response = yield call(deleteUnknownClient, id);
    yield put(deleteUnknownClientSuccess(response.id));
  } catch (error) {
    yield put(deleteUnknownClientFail(error));
  }
}

// add client information
function* onAddClientInformation({ payload: client }) {
  try {
    const response = yield call(addClientInformation, client);
    yield put(addClientInfoSuccess(mapClientV2(response.data)));
  } catch (error) {
    yield put(addClientInfoFail(error));
  }
}

// add new client
function* onAddNewClient({ payload: client }) {
  try {
    const response = yield call(addNewClient, client);
    yield put(addNewClientSuccess(mapUnknownClient(response.client)));
  } catch (error) {
    yield put(addNewClientFail(error));
  }
}

function* clientsSaga() {
  yield takeEvery(GET_CLIENTS, fetchClients),
    yield takeEvery(UPDATE_CLIENT, onUpdateClient),
    yield takeEvery(DELETE_CLIENT_INFO, onDeleteClientInfo),
    yield takeEvery(GET_UNKNOWN_CLIENTS, fetchUnknownClients),
    yield takeEvery(DELETE_UNKNOWN_CLIENT, onDeleteUnknownClient),
    yield takeEvery(ADD_CLIENT_INFO, onAddClientInformation),
    yield takeEvery(ADD_NEW_CLIENT, onAddNewClient);
}

export default clientsSaga;
