import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardFooter,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import { updateCurrentMessage as onUpdateCurrentMessage } from "store/client-profile/actions";
import { daysAgo } from 'utils/date';

const CurrentMessage = ({ clientProfile }) => {
  const [isModelOpen, setIsModelOpen] = useState(false)
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      updateMessage: "",
    },
    validationSchema: Yup.object({
      updateMessage: Yup.string().required("Please Enter Latest Message"),
    }),
    onSubmit: values => {
      const updateValue = {
        id: clientProfile.id,
        update_message: values.updateMessage
      }
      dispatch(onUpdateCurrentMessage(updateValue))
      validation.resetForm()
      setIsModelOpen(false)
      toggle()
    },
  })

  const toggle = () => {
    setIsModelOpen(!isModelOpen)
  }

  const handleUpdate = () => {
    setIsModelOpen(true)
    toggle()
  }

  return (
    <Card>
      <CardHeader className="bg-transparent border-bottom text-uppercase fw-bold">
        Current Message
      </CardHeader>
      <CardBody>
        <CardText className="font-size-14">
          {clientProfile.updateMessage}
        </CardText>
        <Link to="#" className="btn btn-primary" onClick={() => handleUpdate()}>
          Update Message
        </Link>
      </CardBody>
      {clientProfile.updatedAt && (
        <CardFooter className="text-muted">
          {daysAgo(clientProfile.updatedAt)}
        </CardFooter>
      )}
      {!!isModelOpen && (
        <Modal isOpen={isModelOpen} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            Post the latest message
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label>Current message</Label>
                    <p className="text-muted m-b-15">
                      {clientProfile.updateMessage}
                    </p>
                    <Input
                      name="updateMessage"
                      type="textarea"
                      rows="2"
                      placeholder="Update the current message"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.updateMessage &&
                        validation.errors.updateMessage
                          ? true
                          : false
                      }
                    />
                    {validation.touched.updateMessage &&
                    validation.errors.updateMessage ? (
                      <FormFeedback type="invalid">
                        {validation.errors.updateMessage}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </Card>
  )
}

export default CurrentMessage