import {
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_CURRENT_MESSAGE_SUCCESS,
  UPDATE_CURRENT_MESSAGE_FAIL,
  UPDATE_VISA_SUBMISSION_STATUS_SUCCESS,
  UPDATE_VISA_SUBMISSION_STATUS_FAIL,
  UPDATE_STATUS_PERCENTAGE_SUCCESS,
  UPDATE_STATUS_PERCENTAGE_FAIL,
  EDIT_PARTICULAR_DOCUMENT_SUCCESS,
  EDIT_PARTICULAR_DOCUMENT_FAIL,
  DELETE_PARTICULAR_DOCUMENT_SUCCESS,
  DELETE_PARTICULAR_DOCUMENT_FAIL,
  ADD_NEW_DOCUMENT_SUCCESS,
  ADD_NEW_DOCUMENT_FAIL,
  EDIT_PARTICULAR_PAYMENT_SUCCESS,
  EDIT_PARTICULAR_PAYMENT_FAIL,
  ADD_NEW_PAYMENT_SUCCESS,
  ADD_NEW_PAYMENT_FAIL,
  DELETE_PARTICULAR_PAYMENT_SUCCESS,
  DELETE_PARTICULAR_PAYMENT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  profile: [],
  error: {},
};

const ClientProfile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    case GET_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_CURRENT_MESSAGE_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, clientProfile: action.payload },
      };
    case UPDATE_CURRENT_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_VISA_SUBMISSION_STATUS_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, clientProfile: action.payload },
      };
    case UPDATE_VISA_SUBMISSION_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_STATUS_PERCENTAGE_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, clientProfile: action.payload },
      };
    case UPDATE_STATUS_PERCENTAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case EDIT_PARTICULAR_DOCUMENT_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          documentsList: state.profile.documentsList.map(document =>
            document.id.toString() === action.payload.id.toString()
              ? action.payload
              : document
          ),
        },
      };
    case EDIT_PARTICULAR_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_PARTICULAR_DOCUMENT_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          documentsList: state.profile.documentsList.filter(
            document => document.id.toString() !== action.payload.id.toString()
          ),
        },
      };
    case DELETE_PARTICULAR_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_NEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, documentsList: action.payload },
      };
    case ADD_NEW_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case EDIT_PARTICULAR_PAYMENT_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          payments: state.profile.payments.map(payment =>
            payment.id.toString() === action.payload.id.toString()
              ? action.payload
              : payment
          ),
        },
      };
    case EDIT_PARTICULAR_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_PARTICULAR_PAYMENT_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          payments: state.profile.payments.filter(
            payment => payment.id.toString() !== action.payload.id.toString()
          ),
        },
      };
    case DELETE_PARTICULAR_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_NEW_PAYMENT_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, payments: action.payload },
      };
    case ADD_NEW_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ClientProfile;
