export const mapClients = data =>
  data.map(client => ({
    id: client.id,
    firstName: client.first_name,
    lastName: client.last_name,
    fullName: `${client.first_name} ${client.last_name}`,
    dateOfBirth: client.date_of_birth,
    gender: client.gender,
    email: client.email,
    passport: client.passport_no,
    phone: client.phone_no,
    emergencyContact: client.eme_contact,
    address: client.address,
    visaCategory: client.visa_category,
    status: client.is_activated,
    registeredAt: client.created_at,
  }))

export const mapClientV2 = client => ({
    id: client.id,
    firstName: client.first_name,
    lastName: client.last_name,
    fullName: `${client.first_name} ${client.last_name}`,
    dateOfBirth: client.date_of_birth,
    gender: client.gender,
    email: client.email,
    passport: client.passport_no,
    phone: client.phone_no,
    emergencyContact: client.eme_contact,
    address: client.address,
    visaCategory: client.visa_category,
    status: client.is_activated,
    registeredAt: client.created_at,
  })

export const mapUnknownClients = data =>
  data.map(client => ({
    id: client.id,
    firstName: client.first_name,
    lastName: client.last_name,
    fullName: `${client.first_name} ${client.last_name}`,
    email: client.email,
    registeredAt: client.created_at
  }))

export const mapUnknownClient = client => ({
    id: client.id,
    firstName: client.first_name,
    lastName: client.last_name,
    fullName: `${client.first_name} ${client.last_name}`,
    email: client.email,
    registeredAt: client.created_at
  })