/* ALL CLIENTS */
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL";
export const DELETE_CLIENT_INFO = "DELETE_CLIENT_INFO";
export const DELETE_CLIENT_INFO_SUCCESS = "DELETE_CLIENT_INFO_SUCCESS";
export const DELETE_CLIENT_INFO_FAIL = "DELETE_CLIENT_INFO_FAIL";
export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT";
export const ADD_NEW_CLIENT_SUCCESS = "ADD_NEW_CLIENT_SUCCESS";
export const ADD_NEW_CLIENT_FAIL = "ADD_NEW_CLIENT_FAIL";

/* UNKNOWN CLIENTS */
export const GET_UNKNOWN_CLIENTS = "GET_UNKNOWN_CLIENTS";
export const GET_UNKNOWN_CLIENTS_SUCCESS = "GET_UNKNOWN_CLIENTS_SUCCESS";
export const GET_UNKNOWN_CLIENTS_FAIL = "GET_UNKNOWN_CLIENTS_FAIL";
export const ADD_CLIENT_INFO = "ADD_CLIENT_INFO";
export const ADD_CLIENT_INFO_SUCCESS = "ADD_CLIENT_INFO_SUCCESS";
export const ADD_CLIENT_INFO_FAIL = "ADD_CLIENT_INFO_FAIL";
export const DELETE_UNKNOWN_CLIENT = "DELETE_UNKNOWN_CLIENT"; 
export const DELETE_UNKNOWN_CLIENT_SUCCESS = "DELETE_UNKNOWN_CLIENT_SUCCESS"; 
export const DELETE_UNKNOWN_CLIENT_FAIL = "DELETE_UNKNOWN_CLIENT_FAIL"; 