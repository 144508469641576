export const mapVisaStatus = data =>
  data.map(status => ({
    id: status.id,
    status: status.status,
    statusCode: status.status_code,
  }));

export const mapUpdatedVisaStatus = status => ({
  id: status.id,
  status: status.status,
  statusCode: status.status_code,
});
