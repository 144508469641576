import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardFooter,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import { updateStatusPercentage as onUpdateStatusPercentage } from "store/client-profile/actions";
import KnobChart from "components/Common/knobChart";

const ProfileStatusPercentage = ({ modal, setModal, clientProfile }) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      applicationStatus: "",
    },
    validationSchema: Yup.object({
      applicationStatus: Yup.string().required(
        "Please Enter Application Status"
      ),
    }),
    onSubmit: values => {
      const updateValue = {
        id: clientProfile.id,
        application_status: values.applicationStatus,
      };
      dispatch(onUpdateStatusPercentage(updateValue));
      validation.resetForm();
      setIsModelOpen(false);
      toggle();
    },
  });

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handleUpdate = () => {
    setIsModelOpen(true);
    toggle();
  };

  return (
    <Card>
      <CardHeader className="bg-transparent border-bottom text-uppercase fw-bold">
        Profile Completion Percentage
      </CardHeader>
      <CardBody>
        <div className="mb-3">
          <KnobChart
            value={clientProfile.applicationStatus}
            height={150}
            width={150}
            fgColor="#556ee6"
            readOnly={true}
          />
        </div>
        <Link to="#" className="btn btn-primary" onClick={() => handleUpdate()}>
          Update Percentage
        </Link>
      </CardBody>
      {!!isModelOpen && (
        <Modal isOpen={isModelOpen} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            Profile Completion Percentage
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Input
                      name="applicationStatus"
                      type="text"
                      rows="2"
                      placeholder="Update profile completion percentage"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.applicationStatus &&
                        validation.errors.applicationStatus
                          ? true
                          : false
                      }
                    />
                    {validation.touched.applicationStatus &&
                    validation.errors.applicationStatus ? (
                      <FormFeedback type="invalid">
                        {validation.errors.applicationStatus}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </Card>
  );
};

export default ProfileStatusPercentage;
