import * as moment from "moment";

export const formateDate = (date, format) => {
  const dateFormat = format ? format : "DD MMM Y"
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}

export const daysAgo = date => {
  const parsedDate = new Date(date)
  const currentDate = new Date()

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - parsedDate

  // Define the time intervals in milliseconds
  const minute = 60 * 1000
  const hour = minute * 60
  const day = hour * 24
  const year = day * 365

  // Calculate the time units
  const secondsAgo = Math.floor(timeDifference / 1000)
  const minutesAgo = Math.floor(timeDifference / minute)
  const hoursAgo = Math.floor(timeDifference / hour)
  const daysAgo = Math.floor(timeDifference / day)
  const yearsAgo = Math.floor(timeDifference / year)

  // Determine the appropriate time format
  let timeAgoString = ""
  if (yearsAgo >= 1) {
    timeAgoString = yearsAgo === 1 ? "1 year ago" : `${yearsAgo} years ago`
  } else if (daysAgo >= 1) {
    timeAgoString = daysAgo === 1 ? "1 day ago" : `${daysAgo} days ago`
  } else if (hoursAgo >= 1) {
    timeAgoString = hoursAgo === 1 ? "1 hour ago" : `${hoursAgo} hours ago`
  } else if (minutesAgo >= 1) {
    timeAgoString =
      minutesAgo === 1 ? "1 minute ago" : `${minutesAgo} minutes ago`
  } else {
    timeAgoString =
      secondsAgo === 1 ? "1 second ago" : `${secondsAgo} seconds ago`
  }

  return timeAgoString
}
