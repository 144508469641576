import {
  GET_VISA_STATUS,
  GET_VISA_STATUS_SUCCESS,
  UPDATE_VISA_STATUS,
  UPDATE_VISA_STATUS_SUCCESS,
  API_FAIL,
} from "./actionTypes";

export const getVisaStatus = data => ({
  type: GET_VISA_STATUS,
  payload: data,
});

export const getVisaStatusSuccess = data => ({
  type: GET_VISA_STATUS_SUCCESS,
  payload: data,
});

export const updateVisaStatus = data => ({
  type: UPDATE_VISA_STATUS,
  payload: data,
});

export const updateVisaStatusSuccess = data => ({
  type: UPDATE_VISA_STATUS_SUCCESS,
  payload: data,
});

export const apiFailed = error => ({
  type: API_FAIL,
  payload: error,
});
