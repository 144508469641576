import {
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  DELETE_CLIENT_INFO,
  DELETE_CLIENT_INFO_SUCCESS,
  DELETE_CLIENT_INFO_FAIL,
  GET_UNKNOWN_CLIENTS,
  GET_UNKNOWN_CLIENTS_SUCCESS,
  GET_UNKNOWN_CLIENTS_FAIL,
  DELETE_UNKNOWN_CLIENT,
  DELETE_UNKNOWN_CLIENT_SUCCESS,
  DELETE_UNKNOWN_CLIENT_FAIL,
  ADD_CLIENT_INFO,
  ADD_CLIENT_INFO_SUCCESS,
  ADD_CLIENT_INFO_FAIL,
  ADD_NEW_CLIENT,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAIL
} from "./actionTypes"

// All clients
export const getClients = () => ({
  type: GET_CLIENTS,
})

export const getClientsSuccess = clients => ({
  type: GET_CLIENTS_SUCCESS,
  payload: clients,
})

export const getClientsFail = error => ({
  type: GET_CLIENTS_FAIL,
  payload: error,
})

// Update client information
export const updateClient = client => ({
  type: UPDATE_CLIENT,
  payload: client
})

export const updateClientSuccess = client => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: client
})

export const updateClientFail = error => ({
  type: UPDATE_CLIENT_FAIL,
  payload: error
})

// delete client information
export const deleteClientInfo = client => ({
  type: DELETE_CLIENT_INFO,
  payload: client
})

export const deleteClientInfoSuccess = client => ({
  type: DELETE_CLIENT_INFO_SUCCESS,
  payload: client,
})

export const deleteClientInfoFail = error => ({
  type: DELETE_CLIENT_INFO_FAIL,
  payload: error,
})

// get unknown clients
export const getUnknownClients = () => ({
  type: GET_UNKNOWN_CLIENTS,
})

export const getUnknownClientsSuccess = clients => ({
  type: GET_UNKNOWN_CLIENTS_SUCCESS,
  payload: clients,
})

export const getUnknownClientsFail = error => ({
  type: GET_UNKNOWN_CLIENTS_FAIL,
  payload: error,
})

// delete unknown client
export const deleteUnknownClient = client => ({
  type: DELETE_UNKNOWN_CLIENT,
  payload: client
})

export const deleteUnknownClientSuccess = client => ({
  type: DELETE_UNKNOWN_CLIENT_SUCCESS,
  payload: client
})

export const deleteUnknownClientFail = error => ({
  type: DELETE_UNKNOWN_CLIENT_FAIL,
  payload: error
})

// add client information to unknown client
export const addClientInfo = client => ({
  type: ADD_CLIENT_INFO,
  payload: client
})

export const addClientInfoSuccess = client => ({
  type: ADD_CLIENT_INFO_SUCCESS,
  payload: client
})

export const addClientInfoFail = error => ({
  type: ADD_CLIENT_INFO_FAIL,
  payload: error
})

// add new client
export const addNewClient = client => ({
  type: ADD_NEW_CLIENT,
  payload: client,
})

export const addNewClientSuccess = client => ({
  type: ADD_NEW_CLIENT_SUCCESS,
  payload: client,
})

export const addNewClientFail = error => ({
  type: ADD_NEW_CLIENT_FAIL,
  payload: error,
})