import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import TableContainer from "components/Common/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  getVisaStatus as onGetVisaStatus,
  updateVisaStatus as onUpdateVisaStatus,
} from "store/visa-status/actions";
import { getLastSegmentOfURL } from "utils/url";
import { String } from "pages/ClientsTable/ClientsTableCol";

const VisaStatusTable = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [visaStatusState, setVisaStatusState] = useState();
  const clientId = getLastSegmentOfURL(new URL(window.location.href).pathname);
  const dispatch = useDispatch();
  const { visaStatus } = useSelector(state => ({
    visaStatus: state.VisaStatus.visaStatus,
  }));

  const checkVisaType = type => {
    switch (type) {
      case "Eligibility Review":
        return "eligibility";
      case "Medical":
        return "medical";
      case "Additional Documents":
        return "documents";
      case "Interview":
        return "interview";
      case "Biometrics":
        return "biometric";
      case "Background Check":
        return "background";
      case "Final Decision":
        return "decision";
      default:
        return;
    }
  };
  const visaStatusOptions = {
    eligibility: ["E1", "E2", "E3"],
    medical: ["M1", "M2"],
    documents: ["AD1", "AD2", "AD3"],
    interview: ["IA1", "IA2"],
    biometric: ["B1", "B2", "B3"],
    background: ["BC1", "BC2", "BC3"],
    decision: ["FD1", "FD2_APPROVED", "FD2_REFUSED"],
  };

  useEffect(() => {
    dispatch(onGetVisaStatus(clientId));
  }, [clientId]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: (visaStatusState && visaStatusState.status) || "",
      statusCode: (visaStatusState && visaStatusState.statusCode) || "",
    },
    validationSchema: Yup.object({
      statusCode: Yup.string().required("Please select the status code"),
    }),
    onSubmit: values => {
      const updatedValue = {
        id: visaStatusState.id,
        status_code: values.statusCode,
      };
      dispatch(onUpdateVisaStatus(updatedValue));
      validation.resetForm();
      setIsModelOpen(false);
    },
  });

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
    isModelOpen && setVisaStatusState({});
  };

  const editVisaStatusClick = val => {
    setVisaStatusState(val);
    setIsModelOpen(true);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Status",
        accessor: "status",
        Cell: cellProps => {
          return <String {...cellProps} />;
        },
      },
      {
        Header: "Code",
        accessor: "statusCode",
        Cell: cellProps => {
          return <String {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const rowData = cellProps.row.original;
                  editVisaStatusClick(rowData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return visaStatus.length ? (
    <Card>
      <CardBody>
        <CardTitle className="mb-2">Visa Status</CardTitle>
        <TableContainer
          columns={columns}
          data={visaStatus}
          customPageSize={10}
          isShowMoreEnabled={false}
        ></TableContainer>
      </CardBody>
      {!!isModelOpen && (
        <Modal isOpen={isModelOpen} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            Update Visa Status
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label>{visaStatusState.status}</Label>
                    <p>{visaStatusState.statusCode}</p>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Update</Label>
                    <Input
                      type="select"
                      name="statusCode"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.statusCode || ""}
                      invalid={
                        validation.touched.statusCode &&
                        validation.errors.statusCode
                          ? true
                          : false
                      }
                    >
                      {visaStatusOptions[
                        `${checkVisaType(validation.values.status)}`
                      ]?.map(opt => (
                        <option key={opt}>{opt}</option>
                      ))}
                    </Input>
                    {validation.touched.statusCode &&
                    validation.errors.statusCode ? (
                      <FormFeedback type="invalid">
                        {validation.errors.statusCode}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </Card>
  ) : null;
};

export default VisaStatusTable;
