import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_CURRENT_MESSAGE,
  UPDATE_CURRENT_MESSAGE_SUCCESS,
  UPDATE_CURRENT_MESSAGE_FAIL,
  UPDATE_VISA_SUBMISSION_STATUS,
  UPDATE_VISA_SUBMISSION_STATUS_SUCCESS,
  UPDATE_VISA_SUBMISSION_STATUS_FAIL,
  UPDATE_STATUS_PERCENTAGE,
  UPDATE_STATUS_PERCENTAGE_SUCCESS,
  UPDATE_STATUS_PERCENTAGE_FAIL,
  EDIT_PARTICULAR_DOCUMENT,
  EDIT_PARTICULAR_DOCUMENT_SUCCESS,
  EDIT_PARTICULAR_DOCUMENT_FAIL,
  DELETE_PARTICULAR_DOCUMENT,
  DELETE_PARTICULAR_DOCUMENT_SUCCESS,
  DELETE_PARTICULAR_DOCUMENT_FAIL,
  ADD_NEW_DOCUMENT,
  ADD_NEW_DOCUMENT_SUCCESS,
  ADD_NEW_DOCUMENT_FAIL,
  EDIT_PARTICULAR_PAYMENT,
  EDIT_PARTICULAR_PAYMENT_SUCCESS,
  EDIT_PARTICULAR_PAYMENT_FAIL,
  ADD_NEW_PAYMENT,
  ADD_NEW_PAYMENT_SUCCESS,
  ADD_NEW_PAYMENT_FAIL,
  DELETE_PARTICULAR_PAYMENT,
  DELETE_PARTICULAR_PAYMENT_SUCCESS,
  DELETE_PARTICULAR_PAYMENT_FAIL,
} from "./actionTypes";

export const getProfile = data => ({
  type: GET_PROFILE,
  payload: data,
});

export const getProfileSuccess = data => ({
  type: GET_PROFILE_SUCCESS,
  payload: data,
});

export const getProfileFail = error => ({
  type: GET_PROFILE_FAIL,
  payload: error,
});

// UPDATE MESSAGE
export const updateCurrentMessage = data => ({
  type: UPDATE_CURRENT_MESSAGE,
  payload: data,
});

export const updateCurrentMessageSuccess = data => ({
  type: UPDATE_CURRENT_MESSAGE_SUCCESS,
  payload: data,
});

export const updateCurrentMessageFail = error => ({
  type: UPDATE_CURRENT_MESSAGE_FAIL,
  payload: error,
});

// UPDATE VISA SUBMISSION STATUS
export const updateVisaSubmissionStatus = data => ({
  type: UPDATE_VISA_SUBMISSION_STATUS,
  payload: data,
});

export const updateVisaSubmissionStatusSuccess = data => ({
  type: UPDATE_VISA_SUBMISSION_STATUS_SUCCESS,
  payload: data,
});

export const updateVisaSubmissionStatusSuccessFail = error => ({
  type: UPDATE_VISA_SUBMISSION_STATUS_FAIL,
  payload: error,
});

// UPDATE STATUS PERCENTAGE
export const updateStatusPercentage = data => ({
  type: UPDATE_STATUS_PERCENTAGE,
  payload: data,
});

export const updateStatusPercentageSuccess = data => ({
  type: UPDATE_STATUS_PERCENTAGE_SUCCESS,
  payload: data,
});

export const updateStatusPercentageFail = error => ({
  type: UPDATE_STATUS_PERCENTAGE_FAIL,
  payload: error,
});

// EDIT PARTICULAR DOCUMENT
export const editParticularDocument = data => ({
  type: EDIT_PARTICULAR_DOCUMENT,
  payload: data,
});

export const editParticularDocumentSuccess = data => ({
  type: EDIT_PARTICULAR_DOCUMENT_SUCCESS,
  payload: data,
});
export const editParticularDocumentFail = error => ({
  type: EDIT_PARTICULAR_DOCUMENT_FAIL,
  payload: error,
});

// DELETE PARTICULAR DOCUMENT
export const deleteParticularDocument = data => ({
  type: DELETE_PARTICULAR_DOCUMENT,
  payload: data,
});

export const deleteParticularDocumentSuccess = data => ({
  type: DELETE_PARTICULAR_DOCUMENT_SUCCESS,
  payload: data,
});

export const deleteParticularDocumentFail = error => ({
  type: DELETE_PARTICULAR_DOCUMENT_FAIL,
  payload: error,
});

// ADD NEW DOCUMENT
export const addNewDocument = data => ({
  type: ADD_NEW_DOCUMENT,
  payload: data,
});

export const addNewDocumentSuccess = data => ({
  type: ADD_NEW_DOCUMENT_SUCCESS,
  payload: data,
});
export const addNewDocumentFail = error => ({
  type: ADD_NEW_DOCUMENT_FAIL,
  payload: error,
});

// EDIT PARTICULAR PAYMENT
export const editParticularPayment = data => ({
  type: EDIT_PARTICULAR_PAYMENT,
  payload: data,
});

export const editParticularPaymentSuccess = data => ({
  type: EDIT_PARTICULAR_PAYMENT_SUCCESS,
  payload: data,
});
export const editParticularPaymentFail = error => ({
  type: EDIT_PARTICULAR_PAYMENT_FAIL,
  payload: error,
});

// DELETE PARTICULAR PAYMENT
export const deleteParticularPayment = data => ({
  type: DELETE_PARTICULAR_PAYMENT,
  payload: data,
});

export const deleteParticularPaymentSuccess = data => ({
  type: DELETE_PARTICULAR_PAYMENT_SUCCESS,
  payload: data,
});

export const deleteParticularPaymentFail = error => ({
  type: DELETE_PARTICULAR_PAYMENT_FAIL,
  payload: error,
});

// ADD NEW PAYMENT
export const addNewPayment = data => ({
  type: ADD_NEW_PAYMENT,
  payload: data,
});

export const addNewPaymentSuccess = data => ({
  type: ADD_NEW_PAYMENT_SUCCESS,
  payload: data,
});
export const addNewPaymentFail = error => ({
  type: ADD_NEW_PAYMENT_FAIL,
  payload: error,
});
