import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
} from "reactstrap";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateVisaSubmissionStatus as onUpdateVisaSubmissionStatus } from "store/client-profile/actions";
import { useDispatch } from "react-redux";
import StatusBadge from "components/Common/statusBadge";
import { VisaSubmissionStatusBadge } from "components/Common/statusBadge/statusBadge";

//Import Images
import profile1 from "assets/images/profile-img.png";

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
};

const BannerCard = ({ clientInfo, clientProfile }) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [switch3, setswitch3] = useState(false);
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      visaSubmitted: switch3,
    },
    validationSchema: Yup.object({
      visaSubmitted: Yup.string().required(
        "Please update visa submission status"
      ),
    }),
    onSubmit: () => {
      const updateValue = {
        id: clientProfile?.id,
        visa_submitted: switch3 ? "true" : "false",
      };
      dispatch(onUpdateVisaSubmissionStatus(updateValue));
      validation.resetForm();
      setIsModelOpen(false);
      toggle();
    },
  });

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handleUpdate = () => {
    setswitch3(clientProfile?.visaSubmitted);
    setIsModelOpen(true);
    toggle();
  };

  return (
    <Card className="overflow-hidden">
      <div className="bg-primary bg-soft">
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h5 className="text-primary">{clientInfo?.fullName}</h5>
              <p>{clientInfo?.visaCategory}</p>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={profile1} alt="" className="img-fluid" />
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <Row>
          <Col sm="4" className="pt-4">
            {/* TODO: Client image */}
            {/* <div className="avatar-md profile-user-wid mb-4">
                      <img
                        src="#####"
                        alt=""
                        className="img-thumbnail rounded-circle"
                      />
                    </div> */}
            <h5 className="font-size-15 text-truncate">Profile Status</h5>
            <p className="text-muted mb-0 text-truncate">
              <StatusBadge value={clientInfo?.status} />
            </p>
          </Col>
          <Col sm="4" className="pt-4">
            <h5 className="font-size-15 text-truncate">Visa Submission</h5>
            <div className="d-flex gap-3">
              <p className="text-muted mb-0 text-truncate align-self-center">
                <VisaSubmissionStatusBadge
                  value={clientProfile?.visaSubmitted}
                />
              </p>
              <Link
                to="#"
                className="text-success"
                onClick={() => handleUpdate()}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Update
                </UncontrolledTooltip>
              </Link>
            </div>
          </Col>
          {/* TODO: Additional info */}
          {/* <Col sm={8}>
                    <div className="pt-4">
                      <Row>
                        <Col xs="6">
                          <h5 className="font-size-15">#####</h5>
                          <p className="text-muted mb-0">Projects</p>
                        </Col>
                        <Col xs="6">
                          <h5 className="font-size-15">#####</h5>
                          <p className="text-muted mb-0">Revenue</p>
                        </Col>
                      </Row>
                    </div>
                  </Col> */}
        </Row>
      </CardBody>
      {!!isModelOpen && (
        <Modal isOpen={isModelOpen} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            Update Visa Submission Status
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label>Current Status</Label>
                    <p className="text-muted m-b-15">
                      <VisaSubmissionStatusBadge
                        value={clientProfile?.visaSubmitted}
                      />
                    </p>
                    <Label className="form-label me-2">Update Status</Label>
                    <Switch
                      name="visaSubmitted"
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#02a499"
                      onChange={() => {
                        setswitch3(!switch3);
                      }}
                      checked={switch3}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </Card>
  );
};

export default BannerCard;
