import React from "react"
import { Badge } from "reactstrap";
import * as moment from "moment"
import { size, map } from "lodash"

const formateDate = (date, format) => {
  const dateFormat = format ? format : "DD MMM Y"
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}
const toLowerCase1 = str => {
  return str === "" || str === undefined ? "" : str.toLowerCase()
}

const String = cell => {
  return cell.value ? cell.value : ""
}

const Status = cell => {
  return (
    <Badge
      className={
        "font-size-12 badge-soft-" +
        (cell.value === "active"
          ? "success"
          : "danger" 
        )
      }
    >
      {cell.value === 'active' ? 'Active' : 'Deactive'}
    </Badge>
  )
}

// @TODO: for image

// const Img = cell => {
//   return (
//     <>
//       {!cell.value ? (
//         <div className="avatar-xs">
//           <span className="avatar-title rounded-circle">
//             {cell.data[0].name.charAt(0)}
//           </span>
//         </div>
//       ) : (
//         <div>
//           <img className="rounded-circle avatar-xs" src={cell.value} alt="" />
//         </div>
//       )}
//     </>
//   )
// }

export { String, Status, formateDate }
