import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { getProfile as onGetProfile } from "store/client-profile/actions";
import { getLastSegmentOfURL } from "utils/url";
import withRouter from "components/Common/withRouter";
import Breadcrumbs from "components/Common/Breadcrumb";
import BannerCard from "./components/BannerCard";
import PersonalInformation from "./components/PersonalInformation";
import PaymentsTable from "./components/PaymentsTable";
import DocumentsTable from "./components/DocumentsTable";
import CurrentMessage from "./components/CurrentMessage";
import ProfileStatusPercentage from "./components/ProfileStatusPercentage";
import VisaStatusTable from "./components/VisaStatusTable";

const ClientProfile = () => {
  const clientId = getLastSegmentOfURL(new URL(window.location.href).pathname);
  const dispatch = useDispatch();
  const { profile } = useSelector(state => ({
    profile: state.ClientProfile.profile,
  }));

  const { clientInfo, documentsList, payments, clientProfile } = profile;

  useEffect(() => {
    if (profile && !profile.length) {
      dispatch(onGetProfile(clientId));
    }
  }, [clientId]);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Clients" breadcrumbItem="Profile" />
        <Row>
          <Col xl="5">
            {
              <BannerCard
                clientInfo={clientInfo}
                clientProfile={clientProfile}
              />
            }
            {clientInfo && <PersonalInformation clientInfo={clientInfo} />}

            <VisaStatusTable />

            {/* TODO: Profile Tracking */}
            {/* <Card>
              <CardBody>
                <CardTitle className="mb-3">Profile Tracking</CardTitle>
                <div>
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list active" key={"_exp_" + 1}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle bx-fade-right" />
                      </div>
                      <div className="d-flex">
                        <div className="me-3">
                          <i className="bx h4 text-primary" />
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-15">
                              <span className="text-dark">#############</span>
                            </h5>
                            <span className="text-primary">###########</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </CardBody>
            </Card> */}

            {payments && (
              <PaymentsTable payments={payments} clientId={clientInfo?.id} />
            )}
          </Col>
          <Col md="7">
            {profile?.clientProfile && (
              <React.Fragment>
                <CurrentMessage clientProfile={clientProfile} />
                <Col md={6} className="text-center">
                  <ProfileStatusPercentage clientProfile={clientProfile} />
                </Col>
              </React.Fragment>
            )}
            {profile?.documentsList && (
              <DocumentsTable
                documentsList={documentsList}
                clientId={clientInfo?.id}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(ClientProfile);
