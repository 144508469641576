export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const UPDATE_CURRENT_MESSAGE = "UPDATE_CURRENT_MESSAGE";
export const UPDATE_CURRENT_MESSAGE_SUCCESS = "UPDATE_CURRENT_MESSAGE_SUCCESS";
export const UPDATE_CURRENT_MESSAGE_FAIL = "UPDATE_CURRENT_MESSAGE_FAIL";

export const UPDATE_STATUS_PERCENTAGE = "UPDATE_STATUS_PERCENTAGE";
export const UPDATE_STATUS_PERCENTAGE_SUCCESS =
  "UPDATE_STATUS_PERCENTAGE_SUCCESS";
export const UPDATE_STATUS_PERCENTAGE_FAIL = "UPDATE_STATUS_PERCENTAGE_FAIL";

export const UPDATE_VISA_SUBMISSION_STATUS = "UPDATE_VISA_SUBMISSION_STATUS";
export const UPDATE_VISA_SUBMISSION_STATUS_SUCCESS =
  "UPDATE_VISA_SUBMISSION_STATUS_SUCCESS";
export const UPDATE_VISA_SUBMISSION_STATUS_FAIL =
  "UPDATE_VISA_SUBMISSION_STATUS_FAIL";

export const EDIT_PARTICULAR_DOCUMENT = "EDIT_PARTICULAR_DOCUMENT";
export const EDIT_PARTICULAR_DOCUMENT_SUCCESS =
  "EDIT_PARTICULAR_DOCUMENT_SUCCESS";
export const EDIT_PARTICULAR_DOCUMENT_FAIL = "EDIT_PARTICULAR_DOCUMENT_FAIL";

export const DELETE_PARTICULAR_DOCUMENT = "DELETE_PARTICULAR_DOCUMENT";
export const DELETE_PARTICULAR_DOCUMENT_SUCCESS =
  "DELETE_PARTICULAR_DOCUMENT_SUCCESS";
export const DELETE_PARTICULAR_DOCUMENT_FAIL =
  "DELETE_PARTICULAR_DOCUMENT_FAIL";

export const ADD_NEW_DOCUMENT = "ADD_NEW_DOCUMENT";
export const ADD_NEW_DOCUMENT_SUCCESS = "ADD_NEW_DOCUMENT_SUCCESS";
export const ADD_NEW_DOCUMENT_FAIL = "ADD_NEW_DOCUMENT_FAIL";

export const EDIT_PARTICULAR_PAYMENT = "EDIT_PARTICULAR_PAYMENT";
export const EDIT_PARTICULAR_PAYMENT_SUCCESS =
  "EDIT_PARTICULAR_PAYMENT_SUCCESS";
export const EDIT_PARTICULAR_PAYMENT_FAIL = "EDIT_PARTICULAR_PAYMENT_FAIL";

export const ADD_NEW_PAYMENT = "ADD_NEW_PAYMENT";
export const ADD_NEW_PAYMENT_SUCCESS = "ADD_NEW_PAYMENT_SUCCESS";
export const ADD_NEW_PAYMENT_FAIL = "ADD_NEW_PAYMENT_FAIL";

export const DELETE_PARTICULAR_PAYMENT = "DELETE_PARTICULAR_PAYMENT";
export const DELETE_PARTICULAR_PAYMENT_SUCCESS =
  "DELETE_PARTICULAR_PAYMENT_SUCCESS";
export const DELETE_PARTICULAR_PAYMENT_FAIL = "DELETE_PARTICULAR_PAYMENT_FAIL";
