import {
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  DELETE_CLIENT_INFO_SUCCESS,
  DELETE_CLIENT_INFO_FAIL,
  GET_UNKNOWN_CLIENTS_SUCCESS,
  GET_UNKNOWN_CLIENTS_FAIL,
  DELETE_UNKNOWN_CLIENT_SUCCESS,
  DELETE_UNKNOWN_CLIENT_FAIL,
  ADD_CLIENT_INFO_SUCCESS,
  ADD_CLIENT_INFO_FAIL,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  clients: [],
  unknownClients: [],
  success: "",
  error: undefined,
};

const clients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
      };
    case GET_CLIENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: state.clients.map(client =>
          client.id.toString() === action.payload.id.toString()
            ? { client, ...action.payload }
            : client
        ),
      };
    case UPDATE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_CLIENT_INFO_SUCCESS:
      return {
        ...state,
        clients: state.clients.filter(
          client => client.id.toString() !== action.payload.toString()
        ),
      };
    case DELETE_CLIENT_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_UNKNOWN_CLIENTS_SUCCESS:
      return {
        ...state,
        unknownClients: action.payload,
      };
    case GET_UNKNOWN_CLIENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_UNKNOWN_CLIENT_SUCCESS:
      return {
        ...state,
        unknownClients: state.unknownClients.filter(
          client => client.id.toString() !== action.payload.toString()
        ),
      };
    case DELETE_UNKNOWN_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_CLIENT_INFO_SUCCESS:
      return {
        ...state,
        clients: [...state.clients, action.payload],
        unknownClients: state.unknownClients.filter(
          client => client.email.toString() !== action.payload.email.toString()
        ),
      };
    case ADD_CLIENT_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_NEW_CLIENT_SUCCESS:
      return {
        ...state,
        error: "",
        unknownClients: [...state.unknownClients, action.payload],
      };
    case ADD_NEW_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default clients;
