import {
  GET_VISA_STATUS_SUCCESS,
  UPDATE_VISA_STATUS_SUCCESS,
  API_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  visaStatus: [],
  error: {},
};

const VisaStatus = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VISA_STATUS_SUCCESS:
      return {
        ...state,
        visaStatus: action.payload,
      };
    case UPDATE_VISA_STATUS_SUCCESS:
      return {
        ...state,
        visaStatus: state.visaStatus.map(status =>
          status.id.toString() === action.payload.id.toString()
            ? action.payload
            : status
        ),
      };
    case API_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default VisaStatus;
